<template>
  <!-- Modal to create teams -->
  <b-modal id="create-team-modal" centered size="lg" hide-footer>
    <template #modal-header="{ close }">
      <div class="w-100 text-right c-pointer">
        <img
          src="@/assets/images/close.svg"
          alt="close"
          @click="close()"
          width="14"
          height="14"
        />
      </div>
    </template>

    <div class="create-teams-body">
      <div class="text-center">
        <img
          src="@/assets/images/icons/group-icon.svg"
          alt="group"
          width="40"
          height="40"
        />
        <h5>{{ $t("teams.buttons.new_team") }}</h5>
      </div>
      <!-- reference URL ( https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#rendering ) -->
      <ValidationObserver v-slot="{ invalid }" name="add-team-observer">
        <form @submit.prevent="createTeam">
          <ValidationProvider
            name="title"
            rules="required|max:255"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <Input
                :label="`${$t('teams.team.create.title.label')} *`"
                :placeholder="`${$t('teams.team.create.title.placeholder')}`"
                v-model="addTeam.name"
                :showError="errors.length > 0 && errors[0].includes('title')"
                :errorMessage="errors[0]"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="description"
            v-slot="{ errors }"
            rules="required|max:300"
          >
            <div class="form-group">
              <label>{{ $t("teams.team.create.description.label") }}*</label>
              <Textarea
                :placeholder="
                  `${$t('teams.team.create.description.placeholder')}`
                "
                rows="3"
                v-model="addTeam.description"
                :limit="300"
                hasLimit
                postOb
              ></Textarea>
              <span class="error mb-2">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <ValidationProvider name="Language">
            <div class="form-group">
              <Select
                :label="$t('my_profile.language.heading')"
                :placeholder="$t('teams.team.create.language.label')"
                :dropdownArr="allLanguage"
                :value="addTeam.language ? addTeam.language.name : null"
                @click="onSelect($event)"
              ></Select>
            </div>
          </ValidationProvider>

          <div class="justify-content-center d-flex">
            <Button
              :disabled="invalid"
              class="btn-primary mt-2"
              type="submit"
              :pill="true"
            >
              {{ $t("teams.team.create.navigation.title") }}
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    topicId: String
  },
  data() {
    return {
      addTeam: {
        name: "",
        description: "",
        language: ""
      }
    };
  },
  computed: {
    ...mapGetters(["allLanguage"])
  },
  methods: {
    onSelect(value) {
      this.addTeam.language = value;
    },
    createTeam() {
      this.isListLoading = true;
      let obj = {
        name: this.addTeam.name,
        description: this.addTeam.description,
        language: this.addTeam.language.id,
        topicId: this.topicId,
        courseId: this.$route.params.id
      };
      this.$store.dispatch("createTeam", obj).then(() => {
        this.$bvModal.hide("create-team-modal");
        this.$emit("success");
      });
    }
  }
};
</script>
<style lang="scss">
#create-team-modal {
  .modal-body {
    padding: 1rem 24px;
    .create-teams-body {
      h5 {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #0057e0;
        padding: 16px 0;
      }
    }
    .form-group {
      label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
      }
      .lx-select {
        .b-dropdown {
          label {
            color: #25282b;
            font-weight: 500;
            &.placeholder {
              color: #919191;
              font-weight: normal;
            }
          }
        }
      }
      .lxp-textarea {
        textarea {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
